import { isPlatformBrowser } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UserRole } from 'src/definitions/user-role.enum';
import { LoginOAuth } from 'src/models/user';
import { CustomGoogleLoginProvider } from 'src/providers/google-login-provider';
import { SocialLoginService } from 'src/services/social-login.service';
import { AuthService } from 'src/shared-components/ng-login/auth/auth.service';
import { LoadingStore } from 'src/stores/loading.store';
import { DOMAINS, SUBDOMAINS } from 'src/utils/constants';

import { LoginFormComponent } from './login-form/login-form.component';

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'app-login-client',
  templateUrl: './login-client.component.html',
  styleUrls: ['./login-client.component.scss'],
  standalone: true,
  imports: [LoginFormComponent, RouterLink, TranslateModule]
})
/**
 * Login component
 */
export class LoginClientComponent implements OnInit {
  /**
   * Current selected SSO
   */
  selectedSSO?: string;

  /**
   * Frontend domain
   */
  frontEndDomain?: string;

  /**
   * Social login response subscription
   */
  socialLoginSubscription: Subscription | undefined;

  /**
   * Subdomain if exists
   */
  subdomain: string | undefined;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private loading: LoadingStore,
    private socialLoginService: SocialLoginService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const currentUrl = new URL(window.location.href);
      if (this.isSubdomain()) {
        const hostParts = currentUrl.hostname.split('.');

        if (hostParts.length > 1) {
          let removedSubdomain: string | undefined = hostParts.shift()!;
          if (removedSubdomain === 'www') {
            if (!SUBDOMAINS.includes(hostParts[0])) {
              removedSubdomain = hostParts.shift()!;
            } else {
              removedSubdomain = undefined;
            }
          }
          this.subdomain = removedSubdomain;
          currentUrl.hostname = hostParts.join('.');

          this.frontEndDomain = currentUrl.origin;
        }
      } else {
        this.frontEndDomain = currentUrl.origin;
      }
    }
  }

  isSubdomain(): boolean {
    const hostname = window.location.hostname;

    return !DOMAINS.includes(hostname) && DOMAINS.some((domain) => hostname.endsWith(domain));
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.hasOwnProperty('oauthData') && params.hasOwnProperty('provider')) {
        this.loading.start();
        this.selectedSSO = params['provider'].charAt(0) + params['provider'].slice(1).toLowerCase();

        const oauthParameters: LoginOAuth = JSON.parse(params['oauthData']);

        this.socialLoginService
          .login(oauthParameters, params['provider'])
          .pipe(
            finalize(() => {
              this.selectedSSO = undefined;
              this.loading.stop();
            })
          )
          .subscribe((loginOk) => {
            if (loginOk) {
              this.handleSuccessfulLogin();
              this.selectedSSO = undefined;
              this.loading.stop();
            }
          });
      } else {
        this.socialLoginSubscription = this.socialLoginService.loginResponse$.subscribe((loginOk) => {
          if (loginOk) {
            this.handleSuccessfulLogin();
          } else {
            this.selectedSSO = undefined;
          }
        });
      }
    });
  }

  /**
   * Handle the process of social login
   * @param provider
   */
  handleSocialLogin(provider: string): void {
    if ([CustomGoogleLoginProvider.PROVIDER_ID].includes(provider)) {
      if (isPlatformBrowser(this.platformId)) {
        let redirectUrl = `${this.frontEndDomain}/redirect?redirectUrl=${this.frontEndDomain}&sso=${provider}`;
        if (this.isSubdomain() && this.subdomain) {
          redirectUrl += `&subdomain=${this.subdomain}`;
        }
        window.location.href = redirectUrl;
      }
    } else {
      this.loading.start();
      this.selectedSSO = provider;
      this.socialLoginService.signIn(provider);
    }
  }

  /**
   * Method to handle when a user log in successfully
   */
  handleSuccessfulLogin(): void {
    this.authService.successLoginInfoAndRedirect(true, UserRole.CLIENT);
  }
}
