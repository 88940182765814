<div class="login-container gap-ml padding-lg shadow-1 container-border-radius padding-lg background-white">
  <div class="row horizontal-space-between">
    <a href="/home" class="text-btn text-button">
      <ion-icon name="chevron-back"></ion-icon>
      {{ 'CommonTranslations.GoHome' | translate }}
    </a>
    <a href="/operator/login" class="text-btn text-button" data-cy="operator-button">
      {{ 'LoginComponent.Client.IsOperator' | translate }}
    </a>
  </div>

  <!-- Logo -->
  <div class="center row padding-sm">
    <a href="/home">
      <img src="/assets/images/logo-simple-dark.svg" alt="Movenbus Logo" />
    </a>
  </div>

  <h1 class="horizontal-center">{{ 'LoginComponent.Client.Title' | translate }}</h1>
  <app-login-form></app-login-form>
  <div class="column">
    <hr class="divider margin-top register" />
    <div class="column vertical-center horizontal-start gap-lg">
      <button class="btn btn--sso btn--lg" (click)="handleSocialLogin('GOOGLE')">
        <ion-icon class="login-icon" slot="start" src="assets/utils/icons/google.svg"></ion-icon>
        <span class="fill">{{ 'LoginComponent.SSO.LoginGoogle' | translate }}</span>
      </button>
      <div class="column vertical-center">
        <span> {{ 'LoginComponent.NoAccount' | translate }}</span>
        <a class="text-btn text-btn--sm" routerLink="/signup" data-cy="signup-button">
          {{ 'LoginComponent.RegisterClient' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
